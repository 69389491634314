/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import { loadFonts } from './webfontloader';
import vuetify from './vuetify';
import pinia from '../store';
import router from '../router';
import i18n from './i18n';
import './yup';
import * as Sentry from '@sentry/vue';
import { CaptureConsole } from '@sentry/integrations';

// Types
import type { App } from 'vue';

export function registerPlugins(app: App) {
    loadFonts();

    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            Sentry.replayIntegration({
                // Additional SDK configuration goes in here, for example:
                maskAllText: false,
                blockAllMedia: true,
            }),
            new CaptureConsole(),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        trackComponents: true,
        logErrors: true,
        attachProps: true,
        attachStacktrace: true,
    });
    if (import.meta.env.VITE_SENTRY_DSN) {
        try {
            Sentry.setTag('io_version', import.meta.env.VITE_APP_VERSION);
        } catch (error) {
            console.error(error);
        }
    }

    app.use(vuetify).use(router).use(pinia).use(i18n);
}
